import React, {useState} from 'react'


import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import './index.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from "swiper";

import './index.scss'
import Footer from './../Footer'

import Image1 from '../../assets/images/couverture/Larosedesvents-paysage-courverture-1.jpg'
import Image2 from '../../assets/images/couverture/Larosedesvents-paysage-courverture-2.jpg'
import Image3 from '../../assets/images/couverture/Larosedesvents-paysage-courverture-3-corse.jpg'
import Image4 from '../../assets/images/entretien/gazon/Larosedesvents-paysage-entretient-gazon-2.jpg'
import Image5 from '../../assets/images/couverture/Larosedesvents-paysage-courverture-4.jpg'
import Image6 from '../../assets/images/couverture/Larosedesvents-paysage-courverture-5-corse.jpg'
import Image7 from '../../assets/images/couverture/Larosedesvents-paysage-courverture-6-corse-jardin-haut.jpg'
import Image8 from '../../assets/images/entretien/gazon/Larosedesvents-paysage-entretient-gazon-3.jpg'
import Image9 from '../../assets/images/creation/Plantation/Larosedesvents-paysage-creation-plantation-apres.jpg'


const Galerie = () => {
    const datas = [
        {
            id: 1,
            image: `${Image1}`,
        },
        {
            id: 2,
            image: `${Image2}`,
        },
        {
            id: 3,
            image: `${Image3}`,
        },
        {
            id: 4,
            image: `${Image4}`,
        },
        {
            id: 5,
            image: `${Image5}`,
        },
        {
            id: 6,
            image: `${Image6}`,
        },
        {
            id: 7,
            image: `${Image7}`,
        },
        {
            id: 8,
            image: `${Image8}`,
        },
        {
            id: 9,
            image: `${Image9}`,
        },
    ]
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (

    <>
    <div className="galerie-box">
        <div className="galerie-bg">
            <Swiper
            style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
            }}
            loop={true}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper2"
            >

                {datas.map(slide => (
                    <SwiperSlide key={slide.id}>
                        <img src={slide.image} alt=""/>
                    </SwiperSlide>
                ))}

            </Swiper>
            <Swiper
            onSwiper={setThumbsSwiper}
            loop={true}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper"
            >
                {datas.map(slide => (
                    <SwiperSlide key={slide.id}>
                        <img src={slide.image} alt=""/>
                    </SwiperSlide>
                ))}
            </Swiper>

        </div>
    </div>
    <Footer />
  </>
);
}


export default Galerie
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';


import Image1 from '../../../assets/images/creation/Plantation/Larosedesvents-paysage-creation-plantation-avant.jpg'
import Image2 from '../../../assets/images/creation/Plantation/Larosedesvents-paysage-creation-plantation-apres.jpg'

const SliderPlantation = () => {
    return (
        <>
        <ReactCompareSlider
            itemOne={<ReactCompareSliderImage src={Image1}  alt="Image one" />}
            itemTwo={<ReactCompareSliderImage src={Image2}alt="Image two" />}
            />
        </>
    )
}

export default SliderPlantation
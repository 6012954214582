import React from "react";

import SwiperCore, { EffectFade,  Autoplay} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./index.scss"
import Image1 from '../../../assets/images/couverture/Larosedesvents-paysage-courverture-1.jpg'
import Image2 from '../../../assets/images/couverture/Larosedesvents-paysage-courverture-2.jpg'
import Image3 from '../../../assets/images/couverture/Larosedesvents-paysage-courverture-3-corse.jpg'
import Image4 from '../../../assets/images/couverture/Larosedesvents-paysage-courverture-4.jpg'
import Image5 from '../../../assets/images/couverture/Larosedesvents-paysage-courverture-5-corse.jpg'
import Image6 from '../../../assets/images/couverture/Larosedesvents-paysage-courverture-6-corse-jardin-haut.jpg'
import Logo from './../../../assets/images/logo/logo - La Rose des Vents - png - fond noir.png'


SwiperCore.use([EffectFade,  Autoplay]);

// if you want to use array
const slide_img = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
];

const SliderHomeFirst = () => {
  return (
    <div id="slider-home-first">
      <Swiper
        effect={"fade"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 4500,
          duration: 1500,
          disableOnInteraction: false,
        }}
        className="mySwiper"
      >
        {/* Using array */}
        {slide_img.map((img, i) => {
          return (
            <SwiperSlide key={i}>
              <img src={img} alt="" />
            </SwiperSlide>
          );
        })}

      </Swiper>
      <div className="logo">
                <div className="overlay"></div>
                

                <img src={Logo} alt="" />
                <p>Aménager ou réaménager son jardin ? Laissez-nous créer ce qui vous plaît. De la simple modification aux aménagements les plus complexes, notre entreprise saura vous conseiller pour réaliser vos envies.</p>
            </div>
    </div>
  );
};

export default SliderHomeFirst;
import { Link, NavLink } from 'react-router-dom';
import './index.scss';
import LogoS from '../../assets/images/logo/logo-minimal-horizontale.svg'
import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'

const Sidebar = () => {

    const [state, setState] = useState(false)

    const toggle = () => {
        setState(!state)
    }


    
    const [toggleTabs, setToggleTabs] = useState(1);

    const toggleTab = (index) => {
        setToggleTabs(index)
        toggle()
    }

    return (

        <header className="header">
            <div className="header-container">
                <Link className="logo" to='/'>
                    <img src={LogoS} alt="logo" />
                </Link>

                <div onClick={toggle} className="menu-toggle">
                    {state ? <FontAwesomeIcon icon={faXmark} color="#4d4d4e" /> : <FontAwesomeIcon icon={faBars} color="#4d4d4e" />}
                </div>


                <nav className={state ? 'active' : ''} >
                    <ul>
                        <li><NavLink exact="true" to='/' className={toggleTabs === 1 ? 'active' : ''} onClick={() => toggleTab(1)} >Acceuil</NavLink></li>
                        
                        <li class="sub-menu">
                            <NavLink exact="true" activeclassname="active" to='/Creation' className={toggleTabs === 2 ? 'active' : ''} onClick={() => toggleTab(2)} >Création</NavLink>

                        </li>
                        <li class="sub-menu" >
                            <NavLink exact="true" activeclassname="active" to='/Entretient' className={toggleTabs === 3 ? 'active' : ''} onClick={() => toggleTab(3)} >Entretient</NavLink>

                        </li>

                        <li><NavLink exact="true" activeclassname="active" to='/Galerie' className={toggleTabs === 4 ? 'active' : ''} onClick={() => toggleTab(4)} >Galerie</NavLink></li>
                        <li><NavLink exact="true" activeclassname="active" to='/Contact' className={toggleTabs === 5 ? 'active' : ''} onClick={() => toggleTab(5)} >Contact</NavLink></li>
                    </ul>
                </nav>

                <div class="clearfix"></div>

            </div>

        </header>

    )
}

export default Sidebar

import './index.scss'
import Image1 from '../../assets/images/couverture/Larosedesvents-paysage-courverture-2.jpg'
import Footer from '../Footer';
import { NavLink } from 'react-router-dom';
import SliderHomeFirst from './SliderHomeFirst';

const Home = () => {

    
    return (
        <>
        <section>
            <div className='container'>
                <SliderHomeFirst/>

                <div className="content-container">

                    <div className="content active-content first">

                        <div className="left">
                            <img src={Image1} alt="" />
                        </div>

                        <div className="right">
                        <h2>Création</h2>
                        <p>L’aménagement extérieur et la création de jardin est un véritable art. Chez La rose des vents nous possédons le savoir-faire adéquat pour donner vie à vos envies. <br />

                        L’étude paysagère vous permet d’évaluer la superficie, la configuration et l’exposition de la zone à aménager afin de créer une harmonie entre vous et votre extérieur.</p>

                            <div className="button">
                            <NavLink exact="true" activeclassname="active" to='/Creation'>En voir plus !</NavLink>
                            </div>
                        </div>

                    </div>

                    <div className="content active-content reverse second">

                        <div className="left">
                            <img src={Image1} alt="" />
                        </div>

                        <div className="right">
                        <h2>Entretient</h2>
                        <p>Pour profiter totalement de votre jardin toute l’année, il faut en prendre soin. Ces petites plantes sont vivantes, et on besoin de l’intervention d’un jardinier qualifié qui veillera à leur bonne santé et qui exploitera le meilleur d'elle-même. <br />

                        Un entretien régulier assure à vos plantes une croissance optimale et une santé solide. Il permet de réagir rapidement aux différents besoins et contraintes de votre espace. <br />

                        Un suivis de votre jardin s'inscrit dans une suite logique de la création.</p>

                            <div className="button">
                            <NavLink exact="true" activeclassname="active" to='/Entretient'>En voir plus !</NavLink>
                            </div>
                        </div>

                    </div>
                    <div className="button home">
                    <NavLink exact="true" activeclassname="active" to='/Contact'>Contact Nous !</NavLink>
                    </div>
                
                </div>



                <Footer/>
            </div>

        </section>



        </>
    );
}

export default Home
import React from "react";

import SwiperCore, { Pagination, EffectFade,  Autoplay} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import Image1 from '../../../assets/images/creation/Arrosage-automatique/Larosedesvents-paysage-creation-arrosage-automatique-1.jpg'
import Image2 from '../../../assets/images/creation/Arrosage-automatique/Larosedesvents-paysage-creation-arrosage-automatique-2.jpg'
import Image3 from '../../../assets/images/creation/Arrosage-automatique/Larosedesvents-paysage-creation-arrosage-automatique-3.jpg'



SwiperCore.use([EffectFade, Pagination,  Autoplay]);

// if you want to use array
const slide_img = [
  Image1,
  Image2,
  Image3,
];

const SliderArrosage = () => {
  return (
    <div id="slider-creation">
      <Swiper
        effect={"fade"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 5000,
          duration: 1500,
          disableOnInteraction: true,
        }}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {/* Using array */}
        {slide_img.map((img, i) => {
          return (
            <SwiperSlide key={i}>
              <img src={img} alt="" />
            </SwiperSlide>
          );
        })}

      </Swiper>

    </div>
  );
};

export default SliderArrosage;

import React from 'react'

import Footer from './../Footer'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'

import SliderGazon from './SliderGazon'
import SliderTaille from './SliderTaille'
import SliderDebrousaillage from './SliderDebrousaillage'

import './index.scss'

const Galerie = () => {

    const [toggleTabs, setToggleTabs] = useState(1);

    // const [toggleNavs, setToggleNavs] = useState(0);

    // const toggleNav = (index) => {
    //     setToggleNavs(index)
    // }

    const toggleTab = (index) => {
        setToggleTabs(index)
    }

  return (

    <>
        <div className="box">
            <div className="box-content">
                <div className="tab-container" >
                    <div className={toggleTabs === 1 ? 'tabs one active-tabs' : "tabs one"} onClick={() => toggleTab(1)}>Gazon</div>
                    <div className={toggleTabs === 2 ? 'tabs two active-tabs' : "tabs two"} onClick={() => toggleTab(2)}>Taille</div>
                    <div className={toggleTabs === 3 ? 'tabs three active-tabs' : "tabs three"} onClick={() => toggleTab(3)}>Débrousaillage</div>
                    <div className={toggleTabs === 4 ? 'tabs four active-tabs' : "tabs four"} onClick={() => toggleTab(4)}>Engrais et Amendement</div>
                </div>

                <div className="contenu-onglets">

                    <div className={toggleTabs === 1 ? 'content active-content' : 'content'}>

                        <div className="left">
                            <div className="content-left">

                            <SliderGazon/>
                            </div>

                        </div>

                        <div className="right">
                            <h2>Gazon</h2>
                            <p>Idéalement utilisé pour habiller un espace, un beau gazon est l'emblème d’un jardin réussi. Aujourd’hui nous savons travailler différentes espèces de graminés afin de vous proposer une palette végétale adaptée à vos besoins.</p>

                            <div className="button">
                                <NavLink exact="true" activeclassname="active" to='/Contact'>Contact</NavLink>
                            </div>
                        </div>

                    </div>

                    <div className={toggleTabs === 2 ? 'content active-content reverse' : 'content reverse'}>

                        <div className="left">
                            <SliderTaille />
                        </div>

                        <div className="right">
                            <h2>Taille</h2>
                            <p>Plusieurs techniques de tailles sont utilisées : taille douce au sécateur, taille ornementale (cisaille et taille-haie), taille fruitière.</p>

                            <div className="button">
                                <NavLink exact="true" activeclassname="active" to='/Contact'>Contact</NavLink>
                            </div>
                        </div>

                    </div>

                    <div className={toggleTabs === 3 ? 'content active-content' : 'content'}>

                        <div className="left">
                            <SliderDebrousaillage />
                        </div>

                        <div className="right">
                            <h2>Débroussaillages</h2>
                            <p>Préparation à la création, remise en état, prevention incendie, débroussaillage forestier, notre équipe sait répondre à tout type de demande</p>

                            <div className="button">
                                <NavLink exact="true" activeclassname="active" to='/Contact'>Contact</NavLink>
                            </div>
                        </div>

                    </div>
                    
                    <div className={toggleTabs === 4 ? 'content active-content reverse' : 'content reverse'}>

                        <div className="left">
                            <SliderGazon/>
                        </div>

                        <div className="right">
                            <h2>Engrais et amendement</h2>
                                <p>Pour nourrir le végétal et ne pas que les sols s’épuisent, nous appliquons différents engrais et amendements adaptés aux besoins des plantes mais aussi des saisons. Que ca soit, minéral, organique, organo-minéral, liquide, chimiques, fumures …</p>
                            <div className="button">
                                <NavLink exact="true" activeclassname="active" to='/Contact'>Contact</NavLink>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>

        <Footer/>
        
    </>
  )
}


export default Galerie
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';


import Image1 from '../../../assets/images/entretien/taille/Larosedesvents-paysage-entretient-taille-1.jpg'
import Image2 from '../../../assets/images/entretien/taille/Larosedesvents-paysage-entretient-taille-2.jpg'

const SliderTaille = () => {
    return (
        <>
        <ReactCompareSlider
            itemOne={<ReactCompareSliderImage src={Image1}  alt="Image one" />}
            itemTwo={<ReactCompareSliderImage src={Image2}alt="Image two" />}

            />
        </>
    )
}

export default SliderTaille
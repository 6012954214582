
import './App.scss';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import Contact from './components/Contact';
import Entretient from './components/Entretient';
import Creation from './components/Creation';
import Galerie from './components/Galerie';
import Video from '../src/assets/video/animation-logo-larosedesvents-paysages-carré.mp4'
import { useState, useEffect } from 'react';

function App() {

  const [state, setState] = useState(false)
  
  useEffect((state) => {
    const timer = setTimeout(() => {
      setState(!state)
      
      console.log('coucou')
    }, 7000);
    return () => clearTimeout(timer);
  }, []);

  return (
  <>

    <div className={state ? 'first-loader inactive' : 'first-loader'}>
        <video src={Video} type="video/mp4" autoPlay muted></video>
    </div>

    <div className={state ? '' : 'main'}>
      <Routes>
        <Route path="/*" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="contact" element={<Contact />} />
          <Route path="entretient" element={<Entretient />} />
          <Route path="creation" element={<Creation />} />
          <Route path="galerie" element={<Galerie />} />
        </Route>
      </Routes>
    </div>
  </>
  );
}

export default App;
